<template>
  <div class="container">
    <div class="card">
      <div class="content columns">
        <div class="column is-3">Source</div>
        <div class="column is-6">Link</div>
      </div>
    </div>
      <!-- v-for="(item, index) in sourceCompanyLinkList" -->
    <div
      class="card"
      v-for="(v, index) in $v.sourceCompanyLinkList.$each.$iter"
      :key="index">
      <div class="content columns">
        <div class="column is-3">
          <div class="form-group">
            <div>{{v.$model.source_name}}</div>
          </div>
        </div>
        <div class="column is-6">
          <div class="form-group">
            <b-input
              class="form-control"
              placeholder="Link"
              @input="handleLinkInput($v.$invalid)"
              v-model="v.$model.link">
            </b-input>
            <span v-if="!$v.sourceCompanyLinkList.$each[index].link.url" class="required-error">Enter correct url.</span>
            <span v-if="!$v.sourceCompanyLinkList.$each[index].link.maxLength && $v.sourceCompanyLinkList.$each[index].link.url" class="required-error">Link must be at max 250 characters.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import InputView from '../../components/Input.vue'
import { url, maxLength } from 'vuelidate/lib/validators'
/** This component contains the code required to render the source links form. It is currently used on the *Update Product* page */
export default {
  components: {
    // InputView
  },
  props: {
    /** This prop contains the list of sources for whom an input is required */
    sourceCompanyLinkList: Array,
    submitted: Boolean
  },
  data () {
    return {
    }
  },
  // computed: {
  // },
  // watch: {
  // },
  // mounted () {
  // },
  methods: {
    /** This method emits a 'linkInput' event. */
    handleLinkInput (isInvalid) {
      this.$emit('linkInput', isInvalid, this.sourceCompanyLinkList)
    }
  },
  validations: {
    sourceCompanyLinkList: {
      $each: {
        link: {
          url,
          maxLength: maxLength(250)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .container {
    .card {
      color: var(--secondary-text-color);
      box-shadow: none;
      // &:first-child {
      //   border: none;
      //   background: transparent;
      // }
      // &:not(:first-child) {
      & {
        background: var(--background-color);
      }
      margin: 10px;
      margin-left: 0px;
      // border: var(--tertiary-border-color);
      padding: 10px;
      padding-top: 0px;
      padding-bottom: 0px;
      .card-footer {
        border-top: var(--tertiary-border-color);
      }
      .columns {
        align-items: center;
      }
    }
    .row-adder {
      color: #2780eb;
      padding-left: 10px;
    }
  }
</style>
