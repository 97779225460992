<template>
  <div class="keypersons-table-container">
    <div class="user-table">
      <table-vue
        :labels="labels"
        :data="formdata"
        :hideSearch = "true"
        :dateFormat="dateFormat"
        :sortObj="sort"
        :currentPage="currentPage"
        :limit="limit"
        :sortValidation="sortValidation"
        :titleData="'Key Persons'"
        :showEditAction="showEdit"
        :showDeleteAction="showDelete"
        :showAction="showEdit || showDelete"
        @onUpdate="onUpdate"
        @onChangeSort="onChangeSort"
        @updatePage="updatePage"
        @updateLimit="updateLimit"
        @onClickCell="onClickCell" />
    </div>
  </div>
</template>

<script>
import { metaTitle, addressTypes } from '../../../../constant/data'
import tableVue from '../../components/table.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Companies',
  metaInfo () {
    return {
      title: metaTitle.companies
    }
  },
  components: {
    tableVue
  },
  props: {
    formdata: {
      type: Array
    }
  },
  data () {
    return {
      modal: false,
      addressTypes,
      labels: [
        { name: 'First Name', alias: 'firstName' },
        { name: 'Last Name', alias: 'lastName' },
        { name: 'Designation', alias: 'designation' },
        { name: 'Joining Date', alias: 'joiningDate' },
        { name: 'Reports To', alias: 'reportsTo' },
        { name: 'Biography', alias: 'biography' },
        { name: 'Phone', alias: 'phone' },
        { name: 'Email', alias: 'email' },
        { name: 'Website', alias: 'website' },
        { name: 'Linkedin', alias: 'linkedin' },
        { name: 'Twitter', alias: 'twitter' }
      ],
      sortValidation: ['company_id', 'company_name', 'created_at', 'updated_at'],
      dateFormat: ['created_at', 'updated_at'],
      search: '',
      sort: { by: 'asc', field: 'company_id' }
    }
  },

  computed: {
    ...mapState('user', ['userDetails']),
    ...mapState('adminCommon', ['tableSelectionState']),
    showCompany () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.read)].length) {
        return true
      }
      return false
    },
    showEdit () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.update)].length) {
        return true
      }
      return false
    },
    showDelete () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.delete)].length) {
        return true
      }
      return false
    },
    limit: {
      get () {
        return this.tableSelectionState ? this.tableSelectionState.company.limit : 10
      },
      set (newLimit) {
        this.tableSelectionState.company.limit = newLimit
      }
    },
    currentPage: {
      get () {
        return this.tableSelectionState ? this.tableSelectionState.company.currentPage : 1
      },
      set (newPage) {
        this.tableSelectionState.company.currentPage = newPage
      }
    }
  },

  mounted () {
    this.getData()
    this.labels.data = this.addressTypes
  },

  methods: {
    ...mapActions('company', ['getCompanyFilteredList']),
    /** This method toggles the visibility of the modal
     * @public
     */
    onChangeModal (value) {
      this.modal = value
    },

    onChangelogModal (value) {
      this.logModal = value
    },

    /** This method redirects the user to the *Add/Create Compnay* page
     * @public
     */
    onCreate () {
      this.$router.push('company/add')
    },

    /** This method fetches the list data by calling the action method from the store
     * @public
     */
    getData () {
      this.getCompanyFilteredList({
        limit: this.limit,
        skip: (this.currentPage - 1) * this.limit,
        orderBy: this.sort,
        search: this.search
      })
    },

    /** This method redirects the user to the *Update Company* page
     * @public
     */
    onUpdate (data, index) {
      this.$emit('openKeyPersonsModal', data, index)
    },

    /** This method handels the sorting based on the column header clicked
     * @public
     */
    onChangeSort (type) {
      this.sort = type
      this.getData()
    },

    /** This method handels the navigation between the pagination.
     * @public
     */
    updatePage (pageNumber) {
      this.currentPage = pageNumber
      this.getData()
    },

    /** This method updates the number of records on one page in the pagination
     * @public
     */
    updateLimit (pageSize) {
      this.limit = pageSize
      this.getData()
    },
    onClickCell (rowData, index) {
      this.$emit('openKeyPersonsModal', rowData, index)
    }
  }
}
</script>

<style lang="scss" scoped>
.clients-container {
  margin: 20px 0px;
  border-radius: 4px;
  background: var(--primary);
}

.keypersons-table-container {
  overflow: scroll;
  width: 75vw;
}

 .job-log-modal {
    display: flex;
    justify-content: center;
        width: 100%;
    // width: fit-content;
.job-log-inside {
      width: 800px;
      display: flex;
      color: var(--secondary-text-color);
      font-family: Roboto;
      flex-direction: column;
      background: var(--dropdown-backgroud);
      padding: 25px;
      border-radius: 4px;
      .modal-header {
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 10px;
        text-align: left;
      }
      .log {
        display: flex;
        overflow: auto;
      }
    }
  }
</style>
