<template>
  <div class="container">
    <div class="card">
      <div class="content columns">
        <div class="column is-3">Websites</div>
        <div class="column is-6">Link</div>
      </div>
    </div>
    <div class="card">
      <div class="content columns">
        <div class="column is-3">
          <div class="form-group">
            <div> Website </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="form-group">
            <b-input
              class="form-control"
              placeholder="Link"
              v-model="websiteLink"
              @input="handleWebsiteInput($v.websiteLink.$invalid)">
            </b-input>
            <span v-if="!$v.websiteLink.url" class="required-error">Enter correct url.</span>
            <span v-if="!$v.websiteLink.maxLength && $v.websiteLink.url" class="required-error">Link must be at max 250 characters.</span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="card"
      v-for="(v, index) in $v.socialWebsitesList.$each.$iter"
      :key="v.$model.name + index">
      <div class="content columns">
        <div class="column is-3">
          <div class="form-group">
            <div>{{v.$model.name}}</div>
          </div>
        </div>
        <div class="column is-6">
          <div class="form-group">
            <b-input
              class="form-control"
              placeholder="Link"
              @input="handleSocialLinkInput($v.$invalid)"
              v-model="v.$model.link">
            </b-input>
            <span v-if="!$v.socialWebsitesList.$each[index].link.url" class="required-error">Enter correct url.</span>
            <span v-if="!$v.socialWebsitesList.$each[index].link.maxLength && $v.socialWebsitesList.$each[index].link.url" class="required-error">Link must be at max 250 characters.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { url, maxLength } from 'vuelidate/lib/validators'
/** This component contains the code required to render the social links form. It is currently used on the *Add Product* page */
export default {
  components: {
    // InputView
  },
  props: {
    /** This prop contains the list of sources for whom an input is required */
    socialWebsitesList: Array,
    submitted: Boolean,
    websiteLinkValue: String
  },
  data () {
    return {
      websiteLink: ''
    }
  },
  // computed: {
  // },
  watch: {
    websiteLinkValue () {
      this.websiteLink = this.websiteLinkValue
    }
  },
  mounted () {
    // if (this.websiteLinkValue) {
    this.websiteLink = this.websiteLinkValue
    // }
  },
  methods: {
    /** This method emits a 'linkInput' event. */
    handleSocialLinkInput (isInvalid) {
      this.$emit('linkInput', isInvalid, this.socialWebsitesList)
    },
    handleWebsiteInput (isInvalid) {
      this.$emit('websiteInput', isInvalid, this.websiteLink)
    }
  },
  validations: { // Add length validation
    socialWebsitesList: {
      $each: {
        link: {
          url,
          maxLength: maxLength(250)
        }
      }
    },
    websiteLink: {
      url,
      maxLength: maxLength(250)
    }
  }
}
</script>

<style lang="scss" scoped>
  .container {
    .card {
      color: var(--secondary-text-color);
      box-shadow: none;
      // &:first-child {
      //   border: none;
      //   background: transparent;
      // }
      // &:not(:first-child) {
      & {
        background: var(--background-color);
      }
      margin: 10px;
      margin-left: 0px;
      // border: 0.8px solid (var(--voc-bar-background-color));
      padding: 10px;
      padding-top: 0px;
      padding-bottom: 0px;
      .card-footer {
        border-top: 0.8px solid (var(--voc-bar-background-color));
      }
      .columns {
        align-items: center;
      }
    }
    .row-adder {
      color: #2780eb;
      padding-left: 10px;
    }
  }
</style>
