<template>
  <div class="container">
    <div class="card">
      <div class="content columns">
        <div class="column is-3">Year</div>
        <div class="column is-6">{{ bandName }}</div>
      </div>
    </div>
    <div class="card" v-for="(item, index) in dataList" :key="index">
      <div class="content columns">
        <div class="column is-3">
          <div class="form-group">
            <b-field  :type="item.isDuplicate? 'is-danger' : null" :message="item.isDuplicate? 'Please select unique years': null ">
                <b-select placeholder="Select the year" @input="handleYearChange()" v-model="item.year">
                  <option v-for="option in allYearData" :value="option.year" :key="option.id">
                    {{ option.year }}
                  </option>
                </b-select>
            </b-field>
          </div>
        </div>
        <div class="column is-6">
          <div class="form-group">
            <b-select
              v-model="item.band"
              :placeholder="'Select ' + bandName"
              class="form-control"
               @input="handleBandChange()">
              <option :value="null">
                {{ 'Select ' + bandName }}
              </option>
              <option
                v-for="(option, i) in bandList"
                :value="option.value"
                :key="i">
                {{ option.label }}
              </option>
            </b-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Snackbar from '../../../../components/Snackbar'
/** This component contains the code required to render the source links form. It is currently used on the *Update Product* page */
export default {

  props: {
    /** This prop contains the list of sources for whom an input is required */
    dataList: Array,
    allYearData: Array,
    bandName: String,
    bandList: Array,
    submitted: Boolean,
    selectedYears: Array
  },
  data () {
    return {
      yearData: [],
      employeeYears: [],
      revenueYears: [],
      selectedYear: null
    }
  },
  computed: {
  },
  // watch: {
  // },
  mounted () {
  },
  methods: {
    /** This method emits a 'bandInput' event. */
    handleYearChange () {
      const selectedYears = this.dataList.map(({ year }) => {
        return year
      })

      const duplicateYears = selectedYears.filter((val, i) => selectedYears.indexOf(val) !== i)

      if (duplicateYears && duplicateYears.length > 0) {
        Snackbar({ message: 'Please select unique years', type: 'is-danger' })
      }

      this.dataList.map((x) => {
        if (duplicateYears.includes(x.year)) {
          x.isDuplicate = true
        } else {
          x.isDuplicate = false
        }
        return x
      })

      this.$emit('yearInput', this.dataList, !!duplicateYears)
    },
    handleBandChange () {
      this.$emit('bandInput', this.dataList)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .card {
    color: var(--secondary-text-color);
    box-shadow: none;
    // &:first-child {
    //   border: none;
    //   background: transparent;
    // }
    // &:not(:first-child) {
    & {
      background: var(--background-color);
    }
    margin: 10px;
    margin-left: 0px;
    // border: 0.8px solid (var(--voc-bar-background-color));
    padding: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    .card-footer {
      border-top: 0.8px solid (var(--voc-bar-background-color));
    }
    .columns {
      align-items: center;
    }
  }
  .row-adder {
    color: #2780eb;
    padding-left: 10px;
  }
}
</style>
